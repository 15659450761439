/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable prettier/prettier */
.navbar {
  @apply w-full shadow-md bg-navbar-back-light dark:bg-navbar-back-dark;
}

.navbar .primary{
  @apply flex-1 flex items-center justify-center sm:items-end sm:justify-start;
}

.navbar .primary .logo {
  @apply py-2;
}

.navbar .primary .link {
  @apply relative overflow-hidden px-3 h-16 text-base font-medium flex items-center;
  @apply text-gray-700 hover:text-white;
  @apply dark:text-white dark:hover:text-primary;
  transition: all 0.25s;
}

.navbar .primary .link::before {
  @apply absolute w-full h-full -z-10 bottom-0 left-0 right-0 top-full;
  @apply border-b-4 border-b-secondary;
  @apply bg-gray-800 dark:bg-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800;
  content: " ";
  transition: all 0.25s;
}

.navbar .primary .link:hover::before,
.navbar .primary .link.active::before {
  @apply top-0;
}

.navbar .primary .link.active {
  @apply text-white dark:text-primary;    
}


