::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-400;
}

::-webkit-scrollbar-thumb {
  @apply bg-light-secondary hover:to-blue-800;
}

div.app {
  @apply relative h-screen flex flex-col items-start justify-between;
}

.app .page {
  @apply relative w-full h-full flex-1 overflow-auto;
}

.app .page .wrapper {
  @apply relative w-full min-h-full flex-1 flex flex-col justify-start items-center;
}

.app .page section {
  @apply relative w-full;
}

.button {  
  @apply px-2 py-2 border cursor-pointer rounded-sm w-full mx-auto flex flex-row items-center justify-center;
  @apply border-secondary hover:border-primary  bg-secondary dark:bg-secondary hover:bg-primary dark:hover:bg-primary;
  @apply text-white dark:hover:text-white;  
}

.button.success {    
  @apply border-primary hover:border-secondary  bg-primary dark:bg-primary hover:bg-secondary dark:hover:bg-secondary;  
}

.icon-button {  
  @apply border cursor-pointer rounded-sm flex flex-row items-center justify-center;
  @apply border-secondary hover:border-primary  bg-secondary dark:bg-secondary hover:bg-primary dark:hover:bg-primary;
  @apply text-white dark:hover:text-white;  
}

.button.inactive, .icon-button.inactive {
  @apply border-gray-500 bg-gray-400 pointer-events-none text-gray-500;
}

form input, form textarea {
  @apply text-sm h-8 rounded-sm border border-gray-200;
  @apply bg-gray-100 dark:bg-gray-300;
  @apply !px-2 !py-1;
}

form label.label {
  @apply text-sm text-primary;
}

form label + input,form label + textarea {
  @apply !mt-1;
}

.cardbox img {
  @apply scale-100;

  transition: all 0.5s;
}

.cardbox:hover img {
  @apply scale-110;
}

.booking .react-dropdown-select .react-dropdown-select-dropdown{
  overflow-x:hidden;
}
.booking .react-dropdown-select .react-dropdown-select-content {
  width: 100%;
  overflow: hidden;
  padding-right: 10px;
}
.booking .react-dropdown-select .react-dropdown-select-content > span {
  white-space: nowrap;
}

body.modal-open {
  overflow: hidden; /* see "modal-open" in Modal.tsx */
}
