/* stylelint-disable indentation */
/* stylelint-disable scss/at-rule-no-unknown */
/* stylelint-disable prettier/prettier */
.masterhead {
    @apply relative;
}

.masterhead .link {
    @apply w-full flex items-center justify-center;
    @apply px-8 py-3 md:py-4 md:px-10 border border-transparent;
    @apply text-base md:text-lg font-medium rounded-md;
}

.masterhead .link.dark {
    @apply text-indigo-700 bg-indigo-100 hover:bg-indigo-200;    
}

.masterhead .link.light {
    @apply text-white bg-indigo-600 hover:bg-indigo-700;
}
