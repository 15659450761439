/* stylelint-disable prettier/prettier */
.card-stacked{
  background:#fff;
  border-radius:4px;
  color:#fff;
  filter:drop-shadow(0 0 10px #999);
  flex-shrink:0;
  font-family:sans-serif;
  font-size:1.5rem;
  font-weight:bolder;
  padding:2.5rem;  
  transition:transform .25s ease-out;
  height:400px;
  width:600px;
}
/* 
.card-stacked:nth-of-type(1) {
    transform:translate(-40%, 0) rotate(-15deg);
}

.card-stacked .footer {
    align-items:center;
    bottom:30px;
    display:flex;
    font-size:.75rem;
    left:2.5rem;
    position:absolute;
    right:2.5rem;
}

.card-stacked img {
    height:2rem;
    margin-right:6px;
    width:2rem;
}

.card-stacked .answer {
    color:#000;
    background:#fff;
}

.card-stacked .spaces {
    transform:translate(40%, 0) rotate(15deg) translate(0, 10%);
    z-index:2;
}

.card-stacked label {
    height:(200px * 1.4) * 1.2;
    position: absolute;
    transform:translate(40%, 0) rotate(15deg);
    width:200px;
} */