.card3d {
    position: relative;
    transition: transform 0.05s ease;
    transform-style: preserve-3d;
    will-change: transform;    
}
  
/* Slight parallax effect on hover */
.card3d:hover .content {
    transform: translateZ(10px);
}

.card3d .content {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;    
    transition: transform 0.3s ease;
}

/* Darker layer to make text more readable */
/* .card3d::before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index:-1;
} */

@media (prefers-reduced-motion) {
    .card3d {
      transform: none !important;
    }
}