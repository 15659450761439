/* stylelint-disable indentation */
/* stylelint-disable scss/at-rule-no-unknown */
/* stylelint-disable prettier/prettier */
@import "https://fonts.googleapis.com/css2?family=VT323&display=swap";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	@apply h-full bg-gray-100 overflow-hidden;
}

body {
	@apply h-full p-0 m-0 overflow-hidden;
	
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
