/* .amenitiess::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;    
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.1);
    transition: all 0.25s;
}

.amenitiess:hover::before {
    background-color: rgba(0,0,0,0.15);
}
 */
.amenities .feature .container {
  @apply relative cursor-pointer w-full rounded-none flex flex-col items-center justify-between;    

  z-index: 1;
  transform-style: preserve-3d;
  transition: all 0.15s ease;
  will-change: transform;
}

/* 
.amenities .feature .container .card {
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease;
}

.amenities .feature .container:hover .card {
    transform: translateZ(12px);
}

.amenities .feature .container::before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
 */
