.booking {
  @apply w-full px-2 lg:px-0 bottom-1/2 z-20;
  @apply absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2;
}

.booking .items-wrapper {
  @apply w-full lg:w-auto shadow-xl border border-primary py-6 px-8;
  @apply bg-navbar-back-light dark:bg-navbar-back-dark;    
}

.booking .items-container {
  @apply w-full flex flex-col items-center justify-start;
  @apply md:flex-row md:items-end;
  /* md:justify-between; */
  @apply space-y-1 md:space-y-0 space-x-0 whitespace-nowrap;
}

.booking .items-container .item{
  @apply relative w-full flex flex-1 flex-col items-start px-2 whitespace-nowrap;
}

.booking .items-container .item > div {
  @apply w-full;
}

.booking .items-container .item input[type='text'] {
    border-radius: 0px;
    border-width: 1px;
    border-color: rgb(39,84,165);
    background-color: rgb(243,244,246);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 100%;
}

.booking .items-container .item input[type='text']:focus {
  outline: 1px solid rgb(39,84,165);
}
/* .booking .items-container .item div:last-child{
  @apply w-full;
} */

.booking .label {
  @apply text-secondary font-bold dark:text-white mb-1;
}

.booking .datePicker {
  @apply w-full bg-gray-100 text-black rounded-none dark:bg-white py-2 px-3 border border-primary;
}

.booking .react-dropdown-select {
  @apply bg-gray-100 rounded-none text-black dark:bg-white py-2 px-3 border border-primary;
} 

.booking .react-dropdown-select-input {
  @apply text-base text-black;
}

.booking .react-dropdown-select-item {
  @apply bg-gray-200 hover:bg-primary hover:text-white;
} 
